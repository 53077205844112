import React from 'react';
import { useStarknetkitConnectModal } from 'starknetkit';
import { useConnect } from '@starknet-react/core';

const WalletBar = () => {
  const { connect, connectors } = useConnect();
  const { starknetkitConnectModal } = useStarknetkitConnectModal({
    connectors: connectors,
    modalTheme: "dark",
    modalMode: "alwaysAsk"
  });

  const handleConnect = async () => {
    const { connector } = await starknetkitConnectModal();
    if (connector) {
      await connect({ connector });
    }
  };

  return (
    <div>
      <a
        onClick={handleConnect}
        className="btn btn-success d-flex align-items-center ps-3 pe-3"
      >
        <i className="bi bi-wallet-fill me-2"></i> Connect Wallet
      </a>
    </div>
  );
};

export default WalletBar;
